export const Assets = {
  // level
  technician: require("../assets/images/technician.png"),
  general: require("../assets/images/general.png"),
  amateur: require("../assets/images/amateur.png"),
  wave: require("../assets/images/wave.png"),
  facebook: require("../assets/images/facebook.png"),
  google: require("../assets/images/google.png"),
  resultbk: require("../assets/images/resultbk.png"),
  laserbeam: require("../assets/images/laserbeam.png"),
  laserbeam2: require("../assets/images/laserbeam2.png"),
  laserbeam3: require("../assets/images/laserbeam3.png"),
  location: require("../assets/images/location.png"),
  license: require("../assets/images/license.png"),
  loginmark: require("../assets/images/loginmark1.png"),
  apple: require("../assets/images/apple.png"),
  titlelogo: require("../assets/images/titlelogo.png"),
  next: require("../assets/images/next.png"),
  close: require("../assets/images/close.png"),
  beofeng: require("../assets/images/baofeng.png"),

  staticImgs: {
    E501: require("../assets/images/E501.png"),
    E601: require("../assets/images/E601.png"),
    E602: require("../assets/images/E602.png"),
    E603: require("../assets/images/E603.png"),
    E701: require("../assets/images/E701.png"),
    E702: require("../assets/images/E702.png"),
    E703: require("../assets/images/E703.png"),
    E901: require("../assets/images/E901.png"),
    E902: require("../assets/images/E902.png"),
    E903: require("../assets/images/E903.png"),
    General_Diagram: require("../assets/images/General_Diagram.jpg"),
    T1: require("../assets/images/T1.jpeg"),
    T2: require("../assets/images/T2.jpeg"),
    T3: require("../assets/images/T3.jpeg"),
    "E5-1": require("../assets/images/E5-1.jpg"),
    "E6-1": require("../assets/images/E6-1.jpg"),
    "E6-2": require("../assets/images/E6-2.jpg"),
    "E6-3": require("../assets/images/E6-3.jpg"),
    "E7-1": require("../assets/images/E7-1.jpg"),
    "E7-2": require("../assets/images/E7-2.jpg"),
    "E7-3": require("../assets/images/E7-3.jpg"),
    "E9-1": require("../assets/images/E9-1.jpg"),
    "E9-2": require("../assets/images/E9-2.jpg"),
    "E9-3": require("../assets/images/E9-3.jpg"),
    JIM_REED: require("../assets/images/JIM_REED.jpg"),
    JIM_WITH_LESSON: require("../assets/images/JIM_WITH_LESSON.png"),
  },
};
