import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import React from "react";
import { Units } from "../constants/Unit";
import { Ionicons } from "@expo/vector-icons";

const StudyLevels = ({
  imgs,
  levelId,
  info,
  fill,
  title,
  subTitle,
  imgIndex,
  onPress,
  additional,
  progressBar,
}) => {
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View
        style={{
          flexDirection: "row",
          flex: 0.9,
        }}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            source={imgs[imgIndex]}
            style={styles.image}
            resizeMode="contain"
          />
        </View>
        <View style={styles.infoWrapper}>
          {additional ? null : (
            <View style={styles.levelIdWrapper}>
              <Text style={styles.levelIdTxt}>Level {levelId}</Text>
            </View>
          )}
          <Text style={styles.title}>
            {title}
            {subTitle ? <Text style={styles.subTitle}> {subTitle}</Text> : null}
          </Text>
          <Text style={styles.info}>{info}</Text>
        </View>
      </View>
      <View>
        <Ionicons name="chevron-forward-outline" color={"#010A41"} size={20} />
      </View>
    </Pressable>
  );
};

export default StudyLevels;

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    borderColor: "#eee",
    borderWidth: 1,
    borderStyle: "solid",
    marginHorizontal: 11,
    height: 110,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    marginBottom: 15,
    maxWidth: 500,
    width: "100%",
    backgroundColor: "#F2F7FE",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,

    elevation: 8,
    position: "relative",
  },
  image: {
    height: 80,
    width: 70,
  },
  infoWrapper: {
    width: "70%",
  },
  levelIdWrapper: {
    backgroundColor: "#0243DD",
    paddingHorizontal: 11,
    paddingVertical: 2,
    width: 65,
    borderRadius: 12,
    marginBottom: 7,
  },
  levelIdTxt: {
    color: "#fff",
    fontFamily: Units.ftFamily.MontserratB,
    fontSize: 12,
  },
  info: {
    color: "#0243DD",
    fontSize: 13,
    fontFamily: Units.ftFamily.MontserratB,
    marginTop: 10,
  },
  title: {
    fontFamily: Units.ftFamily.MontserratB,
    fontSize: 14,
    color: "#010A41",
    lineHeight: 15,
  },
  subTitle: {
    fontSize: 12,
  },
});
