import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { EDColors } from "../constants/Colors";
import { Units } from "../constants/Unit";

const TransButton = ({ text, handlePress }) => {
  return (
    <TouchableOpacity style={styles.borderBtn} onPress={() => handlePress()}>
      <Text style={styles.btnTxt}>{text.toUpperCase()}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  borderBtn: {
    shadowOffset: { width: 1, height: 2 },
    shadowColor: EDColors.lightGray,
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 3,
    borderWidth: 1,
    borderColor: EDColors.lightGray,
    borderRadius: 20,
    height: Units.isTablet ? 60 : 50,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  btnTxt: {
    fontFamily: Units.ftFamily.KarlaB,
    fontSize: Units.isTablet ? Units.ftSizes.big : Units.ftSizes.medium,
    letterSpacing: 5,
    color: EDColors.text,
    paddingHorizontal: 20,
  },
});
export default TransButton;
