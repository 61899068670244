import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
  StyleSheet,
} from "react-native";
import { useSelector } from "react-redux";
import NetInfo from "@react-native-community/netinfo";
import { useMediaQuery } from "react-responsive";
import { FlatList } from "react-native-gesture-handler";
import { v4 as uuidv4 } from "uuid";
import {
  ReviewData,
  getQuesBySection,
  getSection,
  setScore,
  setResultData,
  setSessionId,
} from "../../actions/LevelAction";

import {
  getQuesDataForPracticeByLevel,
  getSectionData,
} from "../../actions/OfflineAction";

import { useAction } from "../../actions/useAction";
import { EDColors } from "../../constants/Colors";

import styles from "./style";
import {
  LinearlineProgress,
  TopBar,
  GradientButton3,
  StickySidebar,
} from "../../components";
import { Units } from "../../constants/Unit";

import { shuffle2, getLinkBasedOnLevel } from "../../utils/helper";
import { Assets } from "../../constants/Images";
import ExplainModal from "./explainModal";
import { useWindowDimensions } from "react-native";

const alpha = Units.alpha;
const staticImgs = Assets.staticImgs;

const Answer = ({ history }) => {
  const [scrollY, setScrollY] = useState(0);

  const windowHeight = useWindowDimensions().height;

  const { selectedSection, reportsDataForLevel, sectionData, selectedLevel } =
    useSelector((state) => {
      console.log("state : ", state);
      return state.LevelReducer;
    });
  const [loading, setLoading] = useState(true);
  const [renderQuesData, setRenderQuesData] = useState([]);
  const [passedStatus, setPassedStatus] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [progress, setProgress] = useState(0);
  const [selected, setSelected] = useState(0);
  const [actItemIndex, setActItemIndex] = useState(-1);
  const [count, setCount] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const totalCnt = Units.practiceQuesCnt[`level${selectedLevel.LevelId}`];

  const [modalVisible, setModalVisible] = useState(false);

  const handleReviewData = useAction(ReviewData);
  const handleGetSection = useAction(getSection);

  const saveScore = useAction(setScore);
  const saveResultData = useAction(setResultData);
  const saveSessionId = useAction(setSessionId);

  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    // Extract query parameters from the window location
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setQueryParams(params);
  }, []);

  const { free_video } = queryParams;

  useEffect(() => {
    // Handler to call on window scroll
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          setScrollY(window.scrollY); // Update the scrollY state
          ticking = false;
        });
        ticking = true;
      }
    };

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // get questions for practice based on level id
    const subscriber = () => {
      NetInfo.fetch().then((state) => {
        if (state.isConnected) {
          handleOnline();
        } else {
          alert("You are in offline!");
          // navigation.pop();
          history.goBack();
        }
      });
    };
    subscriber();
    return () => {
      setPassedStatus(null);
      setReviewData(null);
      setAllSelected(false);
    };
  }, []);

  const handleOnline = async () => {
    // get questions for online
    const ques = await getQuesDataForPracticeByLevel(selectedLevel);
    const sectionData = await getSectionData(selectedLevel);

    if (ques && sectionData) {
      setAllData(ques, sectionData);
      handleGetSection(sectionData);
    }
  };

  const setAllData = async (ques, section) => {
    // set questions to state variables
    const shuffleData1 = await shuffle2(ques);
    const shuffleData = await shuffle2(shuffleData1);
    getQuesBySection(shuffleData, section, selectedLevel.LevelId)
      .then((tempdata) => {
        console.log("tempdata : ", tempdata);
        if (tempdata?.length === totalCnt && totalCnt >= 35) {
          setRenderQuesData(tempdata);
          setLoading(false);
        } else {
          setAllData(ques, section);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDownloadImageUrl = (name) => {
    if (name) {
      const nm = name.split(".");
      return staticImgs[nm[0]];
    } else {
      return staticImgs.T1;
    }
  };

  const _handleItemPress = async (item, itemIdx, index) => {
    // click handler of answer item
    if (itemIdx === actItemIndex && selected === index) {
      return;
    }
    let temp1 = passedStatus;
    let bb = false;
    for (let i = 0; i < 4; i++) {
      if (temp1[itemIdx * 4 + i]) {
        bb = true;
      }
      temp1[itemIdx * 4 + i] = false;
    }

    temp1[itemIdx * 4 + index] = true;
    setPassedStatus(temp1);
    setSelected(index);
    setActItemIndex(itemIdx);

    let temp = reviewData === null ? [] : reviewData;
    const replaceItem = {
      ...item,
      userAnswer: alpha[index],
    };
    if (!bb) {
      setProgress((100 * (count + 1)) / totalCnt);
      setCount(count + 1);

      temp.push(replaceItem);
    } else {
      temp.splice(itemIdx, 1, replaceItem);
    }
    setReviewData(temp);
    if (count + 1 === totalCnt) {
      handleReviewData(reviewData);
    }
  };

  const _handleNext = () => {
    if (count === totalCnt) {
      let cnt = 0;
      reviewData.map((item) => {
        if (
          item.CorrectAnswer === item.userAnswer ||
          item.Correctanswer === item.userAnswer
        ) {
          cnt++;
        }
      });
      saveScore(parseInt((100 * cnt) / totalCnt));
      saveResultData(reviewData);
      saveSessionId(uuidv4());
      console.log("reviewData : ", reviewData);
      if (free_video === "true") {
        history.push(`/PracticeScore?free_video=${free_video}`);
      } else {
        history.push(`/PracticeScore`);
      }
    } else {
      setModalVisible(true);
    }
  };

  const handleModal = () => {
    setModalVisible(false);
    setAllSelected(true);
  };

  const _renderItem = (quesitem, itemIdx) => {
    let cnt = 0;
    for (let i = 0; i < 4; i++) {
      if (passedStatus[itemIdx * 4 + i] === true) {
        cnt++;
      }
    }
    return alpha.map((item, index) => {
      let ftClr = "#333333";
      let bgClr = EDColors.trans;
      if (cnt !== 0) {
        bgClr =
          passedStatus[itemIdx * 4 + index] === true
            ? EDColors.bggreen
            : EDColors.trans;
        ftClr =
          passedStatus[itemIdx * 4 + index] === true
            ? "#333333"
            : EDColors.lightgrey;
      }
      return (
        <TouchableOpacity
          style={[styles.answerCon, { backgroundColor: bgClr }]}
          key={item + "" + index.toString()}
          onPress={() => _handleItemPress(quesitem, itemIdx, index)}
        >
          <View style={styles.optionCon}>
            <View style={styles.circle}>
              {passedStatus[itemIdx * 4 + index] && (
                <View style={styles.innerCircle}></View>
              )}
            </View>
          </View>
          <View style={styles.letterCon}>
            <Text style={[styles.indexTxt, { color: ftClr }]}>{item}</Text>
          </View>
          <View style={styles.line} />
          <View style={isMobile ? styles.ansTxtConMobile : styles.ansTxtCon}>
            <Text style={[styles.quesTxt2, { color: ftClr }]}>
              {quesitem[`Answer${item}`]}
            </Text>
          </View>
        </TouchableOpacity>
      );
    });
  };

  const _mainRenderItem = ({ item, index }) => {
    let img = "";
    let cnt = 0;
    let bgClr = EDColors.white;
    if (item.IsImageQuestion === "Y") {
      img = getDownloadImageUrl(
        item.ImageUrl === undefined ? item.imageName : item.ImageUrl
      );
    }
    for (let i = 0; i < 4; i++) {
      if (passedStatus[index * 4 + i] === true) {
        cnt++;
      }
    }
    if (cnt === 0 && allSelected) {
      bgClr = EDColors.unselect;
    }
    return (
      <View
        style={{
          padding: 10,
          backgroundColor: bgClr,
          marginVertical: 10,
          borderRadius: 10,
        }}
      >
        <View style={styles.titleContainer}>
          {/* <View>
                        <Text style={styles.question}>Question {index+1}: </Text>
                        <Text style = {styles.noText}>{`(${item.QNo})`}</Text>
                    </View> */}
          <Text style={styles.quesTxt}>
            <Text style={styles.question}>
              Question {index + 1}{" "}
              <Text style={styles.question}>{`(${item.QNo.trim()})`}</Text>:{" "}
            </Text>
            {item.Question}
          </Text>
        </View>

        {item.IsImageQuestion === "Y" && img && (
          <Image source={img} style={styles.imgQues} resizeMode="contain" />
        )}
        {_renderItem(item, index)}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <TopBar
          title={
            selectedLevel === null || selectedLevel === undefined
              ? ""
              : `${selectedLevel.Name}`
          }
          subtitle="Practice Test"
          leave
          isMobile={isMobile}
          history={history}
        />
        <View
          style={
            isMobile
              ? [styles.progressCon, { paddingHorizontal: 10 }]
              : styles.progressCon
          }
        >
          <LinearlineProgress
            progress={progress}
            gradient={EDColors.progress}
            width={5}
            background={EDColors.grey}
          />
        </View>
      </View>
      <View style={styles.contentContainer}>
        {loading ? (
          <ActivityIndicator size="large" color={EDColors.primary} />
        ) : (
          <View style={pageStyles.contentContainer}>
            <ScrollView style={pageStyles.scrollContent}>
              <View
                style={
                  isMobile
                    ? [styles.body, { paddingHorizontal: "5%" }]
                    : [
                        styles.body,
                        free_video === "true"
                          ? { paddingHorizontal: "22%" }
                          : { paddingHorizontal: "20%" },
                      ]
                }
              >
                <FlatList
                  data={renderQuesData}
                  keyExtractor={(item) => item.id}
                  renderItem={_mainRenderItem}
                  showsVerticalScrollIndicator={false}
                />
              </View>
              <View
                style={{
                  width: isMobile ? "100%" : "50%",
                  alignSelf: "center",
                  paddingBottom: 30,
                }}
              >
                <GradientButton3
                  text={"Grade My Exam"}
                  handlePress={_handleNext}
                />
              </View>
              <View style={{ height: 20 }}></View>
              <ExplainModal
                modalVisible={modalVisible}
                onClose={handleModal}
                width={isMobile ? "90%" : "auto"}
              />
            </ScrollView>
            {free_video === "true" && !isMobile && (
              <View
                style={[
                  pageStyles.sidebar,
                  {
                    top: Math.max(0, scrollY - 100),
                    height: windowHeight - 40,
                  }, // Sticky behavior
                ]}
              >
                <StickySidebar
                  customStyle={{
                    position: "absolute",
                    top: 0,
                    right: 10,
                    width: 196,
                  }}
                  link={getLinkBasedOnLevel(selectedLevel)}
                />
              </View>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

const pageStyles = StyleSheet.create({
  contentContainer: {
    flexDirection: "row", // Ensure horizontal layout
  },
  scrollContent: {
    position: "relative",
    // marginLeft: 100, // Offset the main content to the right of the sidebar
  },
  sidebar: {
    position: "absolute",
    right: 10,
    width: 196,
    color: "#0062FF",
    borderRadius: 10,
  },
});

export default Answer;
