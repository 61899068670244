//StickySidebar.js

import React from "react";
import {
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Linking,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { EDColors } from "../constants/Colors";
import { Units } from "../constants/Unit";
import { Assets } from "../constants/Images";
import { Feather } from "@expo/vector-icons";

const StickySidebar = ({ customStyle, link }) => {
  const handlePress = () => {
    // Open the provided link
    Linking.openURL(link);
  };
  return (
    <TouchableOpacity
      style={[styles.sidebar, customStyle]}
      onPress={handlePress}
    >
      <Text style={styles.sidebarHeader}>Watch a free Video lesson!</Text>

      <View style={styles.imageContainer}>
        <Image
          source={Assets.staticImgs.JIM_REED}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </View>
      <Text style={styles.description}>
        Don’t just take tests, actually learn the material!
      </Text>
      <View style={styles.mainBtn}>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1.0 }}
          style={styles.button}
          colors={EDColors.gradient}
        >
          <View style={styles.btnTxtContainer}>
            <Text style={styles.btnTxt}>Create Free Account</Text>
            <Feather
              name="chevron-right" // Icon changes based on state
              size={18}
              color="black"
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            />
          </View>
        </LinearGradient>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  sidebar: {
    color: "#0062FF",
    borderWidth: 1,
    borderColor: "#CFCFCF",
    borderRadius: 10,
  },
  sidebarHeader: {
    fontFamily: Units.ftFamily.MontserratB,
    fontSize: 18,
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#0062FF",
    lineHeight: 24,
    textAlign: "center",
    padding: 10,
  },
  imageContainer: {
    width: 178,
    height: 165,
    marginTop: 0,
    marginBottom: 10,
    marginLeft: "auto",
    marginRight: "auto",
  },
  description: {
    fontFamily: Units.ftFamily.MontserratR,
    fontSize: Units.ftSizes.small,
    lineHeight: 21,
    backgroundColor: EDColors.poolbk,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    margin: 0,
  },
  mainBtn: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 25,
    shadowOffset: { width: 1, height: 2 },
    shadowColor: "black",
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 3,
    margin: 10,
  },
  btnTxtContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  button: {
    flexDirection: "row",
    height: 50,
    paddingHorizontal: 20,
    width: "100%",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  btnTxt: {
    fontFamily: Units.ftFamily.MontserratR,
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: 9,
    letterSpacing: 1,
    color: EDColors.black,
  },
  next: {
    width: 15,
    height: 20,
    marginLeft: 10,
  },
});
export default StickySidebar;
