import React from "react";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

// import bottom's main screens
import StudyScreen from "./screens/study";
import AnswerScreen from "./screens/answer";
import PracticeScoreScreen from "./screens/practiceScore";
import ExamReadiness from "./screens/examReadiness";

import SmartQuiz from "./screens/smartQuiz";
import ReviewScreen from "./screens/review";
import {
  Router,
  Switch,
  Route,
  Redirect,
  BackButton,
  DeepLinking,
} from "react-native-web-router";
const mainStack = new createStackNavigator();

function MainStack() {
  return (
    <Router>
      <Switch>
        <Route
          path={["/", "/:levelId/:levelName"]}
          exact
          component={StudyScreen}
        />
        <Route path={"/Answer"} exact component={AnswerScreen} />
        <Route
          path={["/PracticeScore", "/PracticeScore/:userEmail"]}
          component={PracticeScoreScreen}
        />
        <Route path={"/Review"} exact component={ReviewScreen} />
        <Route
          path={"/ExamHistory/:levelId/:userEmail"}
          component={ExamReadiness}
        />
        {/* <Route path={"/:levelId/:sectionId/:numberOfQuestion/:userEmail"} component={SmartQuiz} /> */}

        <Redirect to={"/"} />
      </Switch>
      <DeepLinking />
    </Router>
  );
}

export default MainStack;
