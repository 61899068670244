import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
  Image,
  Linking,
  Animated,
  LayoutAnimation,
} from "react-native";
import { Assets } from "../../constants/Images";
import { FlatList } from "react-native-gesture-handler";
import { useSelector } from "react-redux";
import { LinearGradient } from "expo-linear-gradient";
import { useMediaQuery } from "react-responsive";
import { Feather } from "@expo/vector-icons";
import {
  trackPracticeForUser,
  getUserUidByEmail,
} from "../../actions/OfflineAction";
import {
  CircularProgressComponent,
  GradientButton3,
  TransButton,
  TopBar,
  LinearlineProgress,
} from "../../components";
import { EDColors } from "../../constants/Colors";
import { Units } from "../../constants/Unit";
import {
  getRenderDataForPracticeResult,
  getCurrentDayTime,
  validationEmail,
  registerUserQuestionData,
  registerAggregateQuestionData,
  registerAggregatePractice,
  getLinkBasedOnLevel,
} from "../../utils/helper";
import styles from "./style";
const PracticeScore = ({ location, history }) => {
  const [queryParams, setQueryParams] = useState({});
  const { selectedLevel, sectionData } = useSelector(
    (state) => state.LevelReducer
  );
  const { userEmail } = useSelector((state) => state.ResultReducer);
  const { score, data, sessionId } = useSelector((state) => {
    return state.ResultReducer;
  });

  const animation = useRef(new Animated.Value(1)).current; // Animated value for height/opacity

  const [isExpanded, setIsExpanded] = useState(true); // State to track expand/collapse
  const [contentHeight, setContentHeight] = useState(300); // Store content height dynamically
  // Expand/Collapse Handler
  const toggleExpand = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);

    // Smoothly animate the height/opacity
    Animated.timing(animation, {
      toValue: isExpanded ? 0 : 1, // Collapse to 0 or expand to 1
      duration: 300, // Duration in milliseconds
      useNativeDriver: false, // Cannot use native driver for height
    }).start();

    setIsExpanded(!isExpanded);
  };

  // Interpolation for height/opacity
  const height = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, contentHeight], // Adjust to your desired height
  });

  const opacity = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1], // Smooth fade-in/out
  });
  const { pathname } = location;

  const [correctCnt, setCorrectTotal] = useState(0);
  const [renderData, setRenderData] = useState(null);
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 680px)",
  });

  useEffect(() => {
    // Extract query parameters from the window location
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setQueryParams(params);
  }, []);

  const { free_video } = queryParams;

  useEffect(() => {
    if (selectedLevel === undefined) {
      history.go(-2);
    }
    console.log("data alksdjh: ", data);
    console.log("selectedLevel : ", selectedLevel);
    let letData = [];
    for (
      let i = 1;
      i <= Units.sectionCount[`level${selectedLevel.LevelId}`];
      i++
    ) {
      const customLevelData = data.filter((item) => item.CustomLessonID === i);
      const totalCount = customLevelData.length ? customLevelData.length : 10;
      const correctCount = customLevelData.length
        ? customLevelData.filter(
            (item) => item.Correctanswer === item.userAnswer
          ).length
        : 10;
      letData.push({
        name: "Lesson " + i,
        totalCnt: totalCount,
        correctCnt: correctCount,
        percent: parseInt((100 * correctCount) / totalCount),
        // SectionId: sectionData[index].SectionId,
        questionNo: customLevelData.map((data) => {
          return {
            QNo: data.QNo,
            status: data.Correctanswer === data.userAnswer,
          };
        }),
      });
    }

    //get the users results per section and calculates percentages
    const tpRenderData = getRenderDataForPracticeResult(
      data,
      sectionData,
      selectedLevel.LevelId
    );
    // recordUserQuestionData()
    recordQuestionLevelData();
    if (userEmail) {
      //if user is coming from the iframe experience we record addition user info
      addTrackingLevelItemForPractice(tpRenderData, userEmail);
      recordUserQuestionData(userEmail);
    }
    setRenderData(letData);
    setCorrectTotal(tpRenderData.total);
    getAllQuestions();
    return () => {
      setRenderData(null);
    };
  }, []);

  const getAllQuestions = async () => {
    setLoading(true);
    // const allQuestions = await getAllQuestionsData(selectedLevel.LevelId);
    // console.log("allQuestions : ", allQuestions)
  };

  const recordUserQuestionData = async (email) => {
    //call these helper functions to record user activity on each question
    registerUserQuestionData(data, email);
  };
  const recordQuestionLevelData = async () => {
    //call these helper functions to record user activity on each question

    registerAggregateQuestionData(data);
    registerAggregatePractice(data, selectedLevel, score);
  };

  const addTrackingLevelItemForPractice = async (tpRenderData, email) => {
    // track for QuestionsForReportsForUser
    if (selectedLevel === undefined) return;

    const userId = await getUserUidByEmail(email);
    const _thisDay = getCurrentDayTime();
    const timeStamp = Date.now();
    const insertItem = {
      dateTime: _thisDay,
      timeStamp: timeStamp,
      Section1Score: tpRenderData.data[0].percent,
      Section2Score: tpRenderData.data[1].percent,
      Section3Score: tpRenderData.data[2].percent,
      Section4Score: tpRenderData.data[3].percent,
      Section5Score: tpRenderData.data[4].percent,
      Section6Score: tpRenderData.data[5].percent,
      Section7Score: tpRenderData.data[6].percent,
      Section8Score: tpRenderData.data[7].percent,
      Section9Score: tpRenderData.data[8].percent,
      Section10Score: tpRenderData.data[9].percent,
      Score: score,
      Pass: score < Units.passPercent ? 0 : 1,
      LevelId: selectedLevel.LevelId,
      Name: "",
      Userid: userId || null,
      userEmail: email,
      UserEmail: email,
      SessionId: sessionId,
      AppPlatform: "web",
    };

    if (validationEmail(email)) {
      trackPracticeForUser(insertItem);
    }
    // NetInfo.fetch().then(state => {
    //     handlePracticeTrackingForUser("new", insertItem, "", state.isConnected);
    // });
  };

  const _handleNext = () => {
    history.push("/Answer");
  };

  const _handleReview = () => {
    history.push("/Review");
  };

  const _handleChart = async () => {
    history.push("/Dashboard");
  };

  const _renderQNo = ({ item }) => {
    return (
      <Text style={item.status ? styles.qNoTextSucceed : styles.qNoTextFailed}>
        {item.QNo}
      </Text>
    );
  };

  const _renderItem = ({ item, index }) => {
    if (item === null || item === undefined) return null;
    return (
      <View style={index % 2 ? styles.itemCon : styles.itemCon2}>
        <View style={{ width: isMobile ? 80 : 100 }}>
          <Text style={styles.txt}>{item.name}</Text>
          {/* <Text style={styles.txt}>{`(${item.SectionId})`}</Text> */}
        </View>
        <View style={[styles.progressCon, { width: isMobile ? "50%" : "65%" }]}>
          <LinearlineProgress
            progress={item.percent}
            gradient={
              item.percent > 50 ? EDColors.progress : EDColors.progress2
            }
            width={5}
            background={EDColors.lightGray}
          />
          <View style={styles.qNosContainer}>
            <FlatList
              data={item.questionNo}
              keyExtractor={(_, index) => index.toString()}
              numColumns={isMobile ? 3 : 10}
              renderItem={_renderQNo}
            />
          </View>
        </View>
        <View style={styles.percentCon}>
          <Text style={styles.txt2}>{item.percent}%</Text>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <TopBar
          title={
            selectedLevel === null || selectedLevel === undefined
              ? ""
              : `${selectedLevel.Name}:`
          }
          // flag="chart-bar"
          // onRightPress = {_handleChart}
          subtitle="Practice Test"
          history={history}
          practice
        />
        <View
          style={{
            width: "100%",
            height: 15,
            backgroundColor: EDColors.topbar,
            marginTop: -1,
          }}
        ></View>
      </View>
      <View style={styles.contentContainer}>
        {!score ? (
          <ActivityIndicator size="large" color={EDColors.third} />
        ) : (
          <View
            style={
              isMobile
                ? [styles.body, { paddingHorizontal: "5%" }]
                : styles.body
            }
          >
            <View style={styles.scoreSection}>
              <View>
                <Text style={styles.scoreTitleTxt}>Test Result</Text>
                <Text
                  style={
                    score < Units.passPercent ? styles.failTxt : styles.passTxt
                  }
                >
                  {score < Units.passPercent ? `FAILED` : `PASSED`}
                </Text>
                <Text style={styles.cntTxt}>
                  {`${correctCnt}/${
                    Units.practiceQuesCnt[`level${selectedLevel.LevelId}`]
                  }`}{" "}
                  Correct
                </Text>
              </View>
              <CircularProgressComponent
                size={Units.isTablet ? 150 : Units.isIOS ? 100 : 80}
                font={Units.ftSizes.extra}
                fill={parseInt(score)}
              />
            </View>
            <View style={styles.reviewSection}>
              {/* Touchable for Collapsible Toggle */}
              <TouchableOpacity
                onPress={toggleExpand}
                style={[
                  styles.subpoolCon,
                  isExpanded ? null : styles.subpoolConWhenCollapsed,
                ]}
              >
                <View style={styles.subPoolConWithBtn}>
                  <Text style={styles.poolTxt}>Subpool scoring</Text>
                  <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1.0 }}
                    style={[
                      styles.button,
                      styles.poolButton,
                      isExpanded ? null : styles.poolButtonCollapsed,
                    ]}
                    colors={EDColors.gradient}
                  >
                    <Feather
                      name={isExpanded ? "chevron-down" : "chevron-up"} // Icon changes based on state
                      size={28}
                      color="black"
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                  </LinearGradient>
                </View>
              </TouchableOpacity>

              <Animated.View
                style={[styles.expandableSection, { height, opacity }]}
              >
                <View
                  onLayout={(event) => {
                    // Capture the height of the content
                    const { height } = event.nativeEvent.layout;
                    setContentHeight(height); // Set the measured height dynamically
                  }}
                >
                  <FlatList
                    data={renderData}
                    keyExtractor={(item, index) => "" + index.toString()}
                    renderItem={_renderItem}
                    showsVerticalScrollIndicator={false}
                  />
                </View>
              </Animated.View>
            </View>
            <View
              style={[
                styles.actionSection,
                isMobile
                  ? { display: "flex", flexDirection: "column-reverse" }
                  : {
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    },
              ]}
            >
              <View style={{ width: isMobile ? "100%" : "50%" }} key="top">
                <TransButton
                  text={"review questions"}
                  handlePress={_handleReview}
                />
              </View>
              <View style={{ height: 10 }}></View>
              <View style={{ width: isMobile ? "100%" : "50%" }} key="bottom">
                <GradientButton3
                  text={"start new exam"}
                  handlePress={_handleNext}
                />
              </View>
            </View>
            <View style={{ height: 10 }}></View>
            {/* Watch a free video lesson section  starts here */}

            {free_video === "true" && (
              <TouchableOpacity
                style={freeVideoLessonStyles.sidebar}
                onPress={() =>
                  Linking.openURL(getLinkBasedOnLevel(selectedLevel))
                }
              >
                <Text style={freeVideoLessonStyles.sidebarHeader}>
                  Watch a free Video lesson!
                </Text>

                <View style={freeVideoLessonStyles.imageContainer}>
                  <Image
                    source={Assets.staticImgs.JIM_WITH_LESSON}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </View>
                <Text style={freeVideoLessonStyles.description}>
                  Don’t just take tests, actually learn the material!
                </Text>
                <View style={freeVideoLessonStyles.mainBtn}>
                  <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1.0 }}
                    style={freeVideoLessonStyles.button}
                    colors={EDColors.gradient}
                  >
                    <View style={freeVideoLessonStyles.btnTxtContainer}>
                      <Text style={freeVideoLessonStyles.btnTxt}>
                        Create Free Account
                      </Text>
                      <Feather
                        name="chevron-right" // Icon changes based on state
                        size={24}
                        color="black"
                        style={{
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                    </View>
                  </LinearGradient>
                </View>
              </TouchableOpacity>
            )}
          </View>
        )}
      </View>
    </View>
  );
};

const freeVideoLessonStyles = StyleSheet.create({
  sidebar: {
    color: "#0062FF",
    borderWidth: 1,
    borderColor: "#CFCFCF",
    borderRadius: 10,
  },
  sidebarHeader: {
    fontFamily: Units.ftFamily.MontserratB,
    fontSize: 32,
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "#0062FF",
    lineHeight: 48,
    textAlign: "center",
    padding: 10,
  },
  imageContainer: {
    maxWidth: 678,
    width: "100%",
    height: 200,
    marginTop: 0,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
  description: {
    fontFamily: Units.ftFamily.MontserratR,
    fontSize: Units.ftSizes.big,
    lineHeight: 21,
    backgroundColor: EDColors.poolbk,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    margin: 0,
  },
  mainBtn: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 25,
    shadowOffset: { width: 1, height: 2 },
    shadowColor: "black",
    shadowOpacity: 0.3,
    shadowRadius: 5,
    elevation: 3,
    marginTop: 20,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "384px",
  },
  btnTxtContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  button: {
    flexDirection: "row",
    height: 50,
    paddingHorizontal: 20,
    width: "100%",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  btnTxt: {
    fontFamily: Units.ftFamily.MontserratR,
    fontWeight: 600,
    textTransform: "uppercase",
    fontSize: 17,
    letterSpacing: 2,
    color: EDColors.black,
  },
  next: {
    width: 15,
    height: 20,
    marginLeft: 10,
  },
});
export default PracticeScore;
