import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    ActivityIndicator,
    TouchableOpacity
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useSelector } from 'react-redux';

import { useMediaQuery } from 'react-responsive';
import { getLevel, getUserPracticeHistory, trackPracticeForUser } from '../../actions/OfflineAction';
import { EDColors } from '../../constants/Colors';
import { Units } from '../../constants/Unit';
import { getAverageOfArrayWithParameter } from '../../utils/helper';
import styles from './style';
import { CircularProgressComponent, ExamReadinessStat, GradientButton4 } from '../../components';
import moment from 'moment';
import { selectOneLevel, setUserEmail } from '../../actions/LevelAction';
import { useAction } from '../../actions/useAction';
import { authInfo, auth } from '../../constants/Config';
import Toast from 'react-native-toast-message'
import { signInWithEmailAndPassword } from 'firebase/auth'

const routes = {
    1: "technician",
    2: "general",
    3: "extra",
    4: "technician",
}
const ExamReadiness = ({ location, history }) => {
    const isMobile = useMediaQuery({
        query: "(max-width: 680px)",
    });
    const { selectedLevel, sectionData } = useSelector(state => state.LevelReducer);
    const [level, setLevel] = useState({})
    const [examReadyLabel, setExamReadyLabel] = useState({ text: "", color: "" })
    const [average, setAverage] = useState(0)
    const [userHistory, setUserHistory] = useState([])
    const selectLevel = useAction(selectOneLevel);
    const saveUserEmail = useAction(setUserEmail)
    const [loading, setLoading] = useState(true)
    let levelId = ""
    let userEmail = ""
    const { pathname } = location
    //parses the url to get userEmail and levelId from the url
    const pathValues = pathname.split('/')
    if (pathValues && pathValues.length) {
        if (pathValues[pathValues.length - 1]) {
            userEmail = pathValues[pathValues.length - 1]
        }
        try {
            if (pathValues[pathValues.length - 2]) {
                const parsedLevel = parseInt(pathValues[pathValues.length - 2])
                if (parsedLevel === 1) {
                    levelId = 4
                } else {
                    levelId = parsedLevel
                }
            }
        } catch (e) {

        }

    }


    useEffect(() => {
        try {
            if (levelId && parseInt(levelId)) {
                //if level exists and is correct we again log the user in with a default account so that firebase wont block the requests
                signInWithEmailAndPassword(auth, authInfo.email, authInfo.password).then((user) => {
                    if (user.user) {
                        //after successful login we fetch the level we  got from the url and users exam history
                        getLevelData()
                        getUserHistory()

                    }
                }).catch((err) => {
                    console.log("SIGN IN ERROR : ", err)
                })

            }
        } catch (e) {
            console.log("SIGN IN ERROR TRY: ", e)

        }

    }, []);

    const handleStartNewTest = () => {
        selectLevel(level)
        saveUserEmail(userEmail)
        history.push("/Answer");

    }
    const getLevelData = async () => {
        //gets selected level id details from firebase
        setLoading(true)
        var networkTimer = setTimeout(() => {
            Toast.show({
                type: 'error',
                text1: 'Taking too long? Tap here to reload',
                text2: 'There seems to be a delay connecting to our servers.',
                autoHide: false,
                onPress: () => {
                    history.go(0)
                }
            });
        }, 8000);

        getLevel().then((levels) => {
            clearTimeout(networkTimer)
            setLoading(false)
            if (levels && levels.length) {
                const allLevels = [...levels, {
                    LevelId: 5,
                    Name: "General License",
                    ShortDesc: "Prerequisite: Technician",
                    Status: true,
                    id: "379cb8fa-daa9-11ed-afa1-0242ac120002"
                },
                {
                    LevelId: 6,
                    Name: "Amateur Extra License",
                    ShortDesc: "Prerequisite: General",
                    Status: true,
                    id: "379cb8fa-daa9-11ed-afa1-0823uy87223"
                }
                ]
                const selectedLevel = allLevels.filter((level) => level.LevelId === levelId)
                if (selectedLevel && selectedLevel[0]) {
                    setLevel(selectedLevel[0])
                }
            }
        }).catch((err) => {
            clearTimeout(networkTimer)
            console.log("ERORROEOROE ASD : ", err)
            setLoading(false)
            alert("Please login or check your network status.\n" + err);
        });
    }

    const getUserHistory = async () => {
        var networkTimer = setTimeout(() => {
            Toast.show({
                type: 'error',
                text1: 'Taking too long? Tap here to reload',
                text2: 'There seems to be a delay connecting to our servers.',
                autoHide: false,
                onPress: () => {
                    history.go(0)
                }
            });
        }, 11000);
        try {
            if (userEmail && levelId) {
                //gets users past 3 practice test histories
                const userHistory = await getUserPracticeHistory(userEmail, levelId)
                clearTimeout(networkTimer)


                setUserHistory(userHistory)
                if (userHistory) {
                    //calculate average of the past 3 attempts
                    const average = getAverageOfArrayWithParameter(userHistory, 'Score')
                    setAverage(average || 0)
                    //sets the appropriate messages according to the average of the last three exams
                    if (userHistory.length < 3) {
                        setExamReadyLabel({
                            text: "Take More Practice Tests",
                            color: EDColors.yellow
                        })
                    } else {

                        if (average >= 85) {
                            setExamReadyLabel({
                                text: "Ready",
                                color: EDColors.green
                            })
                        } else if (average >= 74 && average < 85) {
                            setExamReadyLabel({
                                text: "Almost Ready",
                                color: EDColors.yellow
                            })
                        } else if (average < 74) {
                            setExamReadyLabel({
                                text: "Not Ready",
                                color: EDColors.red
                            })
                        }
                    }
                } else {
                    setExamReadyLabel({
                        text: "Not Ready",
                        color: EDColors.red
                    })
                }
            }
        } catch (e) {
            clearTimeout(networkTimer)
        }

    }

    const getColor = (score) => {
        //returns the color for the view based on the score
        if (score >= 85) {
            return EDColors.green
        } else if (score >= 74 && score < 85) {
            return EDColors.yellow
        } else {
            return EDColors.red
        }
    }

    const getLevelIdMapping = (levelId) => {

        if (levelId === 4) {
            return 1
        } else if (levelId === 5) {
            return 2
        } else if (levelId === 6) {
            return 3
        } else {
            return levelId
        }

    }

    return (
        <View style={styles.container}>
            <View style={styles.topContainer}>
                <View style={{ width: "100%", height: 140, backgroundColor: EDColors.white, marginTop: -1, alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={[styles.txt, isMobile ? styles.headerMobile : {}]}>

                        Level {getLevelIdMapping(level.LevelId)}: {level.Name}

                    </Text>
                </View>
            </View>
            <View style={styles.contentContainer}>
                <Text style={styles.txt2}>
                    Exam Readiness: <Text style={[styles.txt6, { color: examReadyLabel.color }]}>{examReadyLabel.text}</Text>
                </Text>
                <Text style={styles.txt3}>
                    You must score an average 85% to be exam ready
                </Text>
                <View style={[styles.statContainer, { width: isMobile ? '90%' : '70%', paddingHorizontal: isMobile ? 20 : 100 }]}>
                    <ExamReadinessStat
                        title={"Average Score"}
                        subtitle={"Last 3 attempts"}
                        progress={average}
                    />
                    {
                        userHistory && userHistory.map((history, index) => (
                            <ExamReadinessStat
                                key={`${index}`}
                                title={history.Score < 74 ? "Fail" : "Pass"}
                                subtitle={moment(history.timeStamp).format("MMM D, YYYY hh:mm a")}
                                progress={history.Score}
                                small
                                isMobile={isMobile}
                                textColor={history.Score < 74 ? EDColors.red : EDColors.green}
                                dialColor={getColor(history.Score)}
                            />
                        ))
                    }
                </View>
            </View>
            <View style={{ width: isMobile ? "80%" : "40%", alignSelf: "center", marginVertical: 30 }}>
                <TouchableOpacity style={styles.mainButton} onPress={handleStartNewTest} disabled={loading}>
                    {
                        loading ? (
                            <ActivityIndicator size="small" color={EDColors.blue} />
                        ) : (
                            <Text style={styles.buttonTxt}>Start New Practice Test</Text>
                        )
                    }

                </TouchableOpacity>

            </View>
        </View>
    );

};

export default ExamReadiness;
